@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.email-preview-image {
  overflow: hidden;
  max-width: 500px;

  @media ($wx-isMobile) {
    width: 40vw;

    @media ($wx-xs-max) {
      width: 90%;
    }
  }
  @media ($wx-isNotMobile) {
    width: 30vw;

    @media ($wx-xl-min) {
      width: 25vw;
      margin-left: 50px;
    }
  }

  &--background-color {
    background-color: var(--color-emailGreyBackground);
  }
  &--container {
    width: auto;
    border: 1px solid var(--color-border-theme);
    border-radius: var(--border-radius-lg);
    background-color: var(--color-emailGreyBackground);
    box-shadow: var(--box-shadow-high-elevation);

    @media ($wx-isMobile) {
      max-width: 90%;
      margin-inline: auto;
    }

    // <v-img>
    ::v-deep .v-image {
      display: block;
      height: auto;
      margin-inline: auto;
    }
  }
}

// Chip Selector with `all` as first option
.multi-chip-selector {
  &.v-select {
    ::v-deep .v-select__slot {
      .v-select__selections {
        position: relative;
        min-height: 0;
        transition: var(--smooth-transition);

        // when chip are displayed
        $chipSmallVerticalSpacer: 4px;

        &:has(.v-chip) {
          margin-top: $chipSmallVerticalSpacer;
          padding-bottom: $chipSmallVerticalSpacer;

          &:has(.v-size--default) {
            margin-top: ($chipSmallVerticalSpacer + 2px);
            &:has(.v-chip--outlined) {
              margin-top: (($chipSmallVerticalSpacer * 2) + 2px);
              padding-bottom: ($chipSmallVerticalSpacer / 2);
            }
          }
          &:has(.v-size--large) {
            margin-top: ($chipSmallVerticalSpacer * 3);
          }
        }

        .v-chip {
          margin: 2px;

          &.v-size--large {
            margin-inline: 3px;
          }
        }
      }
    }
  }
}

// Time Coverage's fieldset
.coverage-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;

  &__period-lenght {
    max-width: 100px;
  }

  @media ($wx-sm-min) {
    flex-direction: row;
  }
}

.day-selector {
  max-width: 7em;
  min-width: 5.5em;
}

// `Hour`
.time-picker {
  // input field
  &__activator {
    max-width: 7em;
    min-width: 5.2em;
  }
  // modal dialog
  ::v-deep &.v-picker {
    max-width: 290px;
    min-width: 290px;
  }
}

.timezone-selector {
  max-width: 250px;
  min-width: 10em;
}

.form-footer-actions {
  margin-top: 3rem;
  border-color: transparent;
  border-radius: 0;
}
